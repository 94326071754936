/*jshint latedef: false */
/**********************************************************************************************/
/** JavaScript Bundle - Frontend **************************************************************/
/**********************************************************************************************/

(function($) {

function randomString(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var randomString = '';
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

/**********************************************************************************************/
/** Responsive Transition Handler *************************************************************/
/**********************************************************************************************/

var respond_size = 0;
var breakdebug_timeout;
 
function be_responsive(size) {
  console.log("Breakpoint hit: " + size);

  // Breakpoint debugger
  try {
    $('#breakpoint').css('display', 'block');
    switch (respond_size) {
      case 320:
        $('#breakpoint').html('Breakpoint: 320 [XS]');
      break;
      case 500:
        $('#breakpoint').html('Breakpoint: 500 [SM]');
      break;
      case 768:
        $('#breakpoint').html('Breakpoint: 768 [MD]');
      break;
      case 992:
        $('#breakpoint').html('Breakpoint: 992 [LG]');
      break;
      case 1280:
        $('#breakpoint').html('Breakpoint: 1280 [XL]');
      break;
      default: 
        $('#breakpoint').html('UNKNOWN');
      break;
    }  
    clearTimeout(breakdebug_timeout);
    breakdebug_timeout = setTimeout(function() { $('#breakpoint').css('display', 'none'); }, 1000);
  } catch(e) {
  }

  // House promos
  setTimeout(function() { house_promos(); }, 3000);
  
}

function width_to_respond_size(width) {
  if (width < 500) {
    return 320;
  } else if (width < 768) {
    return 500;
  } else if (width < 992) {
    return 768;
  } else if (width < 1280) {
    return 992;
  } else {
    return 1280;  
  }
}

/**********************************************************************************************/
/** Fix Vertical Heights **********************************************************************/
/**********************************************************************************************/

var fixvh_timeout;
var fixvh_lastsize = $(window).innerWidth();
var fixvh_active = false;
var fixvh_enabled = true;

function fixvh_reset() {
  if (fixvh_enabled === true) {
    if (fixvh_active === true) {
      if (fixvh_lastsize !== $(window).innerWidth()) {
        // Reset all the heights if we've resized...
        console.log("Resetting all .fixvh heights and .fixtp padding-tops...");
        $('.fixvh').each(function() {
          $(this).height('');        
        });
        $('.fixtp').each(function() {
          $(this).css('padding-top', '');
        });
        fixvh_lastsize = $(window).innerWidth();
        fixvh_active = false;
      }
    }
  }
}

function fixvh() {
  var current_height = 0;
  var current_padding = 0;
  var computed_padding = '';
  var new_height = 0;
  var new_padding = 0;
  if (fixvh_enabled === true) {
    // Check for and fix any odd-number heights among our elements...
    $('.fixvh').each(function() {    
      current_height = $(this).height();
      if (current_height % 2 > 0) {
        new_height = current_height - 1;
        console.log(".fixvh fixed an odd height!", current_height, '->', new_height, $(this).context);
        $(this).height(new_height);
        fixvh_active = true;
      }
    });
    // And also check for and fix any odd-number padding-tops...
    $('.fixtp').each(function() {    
      current_padding = parseInt($(this).css('padding-top').replace('px', ''));
      computed_padding = current_padding + 'px';
      if (current_padding % 2 > 0) {
        new_padding = current_padding - 1;
        console.log(".fixtp fixed an odd padding-top!", $(this).css('padding-top'), '->', new_padding, $(this).context);          
        $(this).css('padding-top', new_padding + 'px');
        fixvh_active = true;
      } else if ($(this).css('padding-top') !== computed_padding) {
        new_padding = computed_padding;
        console.log(".fixtp fixed a fractional padding-top!", $(this).css('padding-top'), new_padding, $(this).context);
        $(this).css('padding-top', new_padding + 'px');
        fixvh_active = true;
      }
    });        
  }
}

/**********************************************************************************************/
/** Sticky Footer *****************************************************************************/
/**********************************************************************************************/

var original_footer_margin = -1;
var stick_footer_timeout;
var stick_footer_checks = 0;

function stick_footer() {
  var viewport_height = $(window).height();
  var document_height = $('html').innerHeight();
  var cur_diff = viewport_height - document_height;
  var cur_margin = parseInt($('#page-wrapper').css('padding-bottom'));
  var new_margin = 0;
  stick_footer_checks ++;
  // If this is the first time we've messed
  // with the margins, remember the current
  // value as the original minimum...
  if (original_footer_margin === -1) {
    original_footer_margin = cur_margin;
  }
  if (viewport_height > document_height) {
    // Pad the footer if the page isn't tall
    // enough for it to appear at the bottom...
    console.log('Viewport height at ' + viewport_height.toString() + ' exceeds document height at ' + document_height.toString());    
    new_margin = cur_diff + cur_margin;    
    console.log('Footer top margin currently ' + cur_margin.toString() + ', needs to be ' + new_margin.toString());
    $('#page-wrapper').css('padding-bottom', new_margin.toString() + 'px');
  } else if ((document_height > viewport_height) && (cur_margin > original_footer_margin)) {
    // Otherwise if the page is too tall, check
    // to see if we can shed any margin to bring
    // it back to the original...
    console.log('Document height at ' + document_height.toString() + ' exceeds viewport height at ' + viewport_height.toString());
    new_margin = cur_diff + cur_margin;
    if (new_margin < original_footer_margin) {
      new_margin = original_footer_margin;
    }
    console.log('Footer top margin currently ' + cur_margin.toString() + ', needs to be ' + new_margin.toString());
    $('#page-wrapper').css('padding-bottom', new_margin.toString() + 'px');
  }
  if (stick_footer_checks >= 5) {
    stick_footer_checks = 0;
  } else {
    // Check everything again in a little while...    
    clearTimeout(stick_footer_timeout);    
    stick_footer_timeout = setTimeout(function() { stick_footer(); }, 10);
  }  
}

/**********************************************************************************************/
/** OneSignal *********************************************************************************/
/**********************************************************************************************/

var onesignal_triggered = false;

function onesignal_trigger() {

  if (! onesignal_triggered) {

    onesignal_triggered = true;    

    try{
      window._oneSignalInitOptions.promptOptions = {
        slidedown: {
          prompts: [{
            type: "push",
            autoPrompt: true,
            text: {
              actionMessage: "Get instant notifications of the latest Gaming News, Reviews and Features from VGC?",
              acceptButton: "OK",
              cancelButton: "No thanks",
            }
          }]
        }
      };
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(function() {
        window.OneSignal.init(window._oneSignalInitOptions);
      });
      console.log("OneSignal Triggered");
    } catch(e) {
      console.log("OneSignal Triggered (but not available)");
    }

  }

}

/**********************************************************************************************/
/** House Promos ******************************************************************************/
/**********************************************************************************************/

function house_promos() {

  if ($('body').hasClass('logged-in')) {
    return;
  }

/*
    case 320:
    case 500:
    case 768:
    case 992:
    case 1280: */

  if (respond_size >= 992) {

    // Desktop MPU #1
    if (
      $('#nn_mpu1').height() > 0 &&
      $('#nn_mpu1').is(":visible") &&
      $('#nn_mpu1').find('div').length > 0 &&
      (
        $('#nn_mpu1').hasClass('adm-ad-rendered') ||
        $('#nn_mpu1').hasClass('adm-ad-loading') ||
        $('#nn_mpu1').hasClass('adm-ad-empty') ||
        $('#nn_mpu1').hasClass('adm-ad-skin')
      )
    ) {
      console.log("#nn_mpu1 has an advert");
      $('#house_mpu1').hide();
      $('#nn_mpu1').show();
    } else {
      console.log("#nn_mpu1 swapped for house promo");
      $('#nn_mpu1').hide();
      $('#house_mpu1').show().show().css('display', 'flex');

      var mpu1_observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          console.log("Mutation on #nn_mpu1!", mutation);
          var attributeValue = $(mutation.target).prop(mutation.attributeName);
          if (attributeValue.includes('adm-ad')) {
            mpu1_observer.disconnect();
            house_promos();
          }
        });
      });
      try {
        mpu1_observer.observe($('#nn_mpu1')[0], {
          attributes: true,
          attributeFilter: ['class']
        });
      } catch(e) {
      }
    }

    // Desktop MPU #2
    if (
      $('#nn_mpu2').height() > 0 &&
      $('#nn_mpu2').is(":visible") &&
      $('#nn_mpu2').find('div').length > 0 &&
      (
        $('#nn_mpu2').hasClass('adm-ad-rendered') ||
        $('#nn_mpu2').hasClass('adm-ad-loading') ||
        $('#nn_mpu2').hasClass('adm-ad-empty') ||
        $('#nn_mpu2').hasClass('adm-ad-skin')
      )
    ) {
      console.log("#nn_mpu2 has an advert");
      $('#house_mpu2').hide();
      $('#nn_mpu2').show();
    } else {
      console.log("#nn_mpu2 swapped for house promo");
      $('#nn_mpu2').hide();
      $('#house_mpu2').show().css('display', 'flex');

      var mpu2_observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          console.log("Mutation on #nn_mpu2!", mutation);
          var attributeValue = $(mutation.target).prop(mutation.attributeName);
          if (attributeValue.includes('adm-ad')) {
            mpu2_observer.disconnect();
            house_promos();
          }
        });
      });
      try {
        mpu2_observer.observe($('#nn_mpu2')[0], {
          attributes: true,
          attributeFilter: ['class']
        });
      } catch(e) {
      }
    }

    // Desktop MPU #3
    if (
      $('#nn_mpu3').height() > 0 &&
      $('#nn_mpu3').is(":visible") &&
      $('#nn_mpu3').find('div').length > 0 &&
      (
        $('#nn_mpu3').hasClass('adm-ad-rendered') ||
        $('#nn_mpu3').hasClass('adm-ad-loading') ||
        $('#nn_mpu3').hasClass('adm-ad-empty') ||
        $('#nn_mpu3').hasClass('adm-ad-skin')
      )
    ) {
      console.log("#nn_mpu3 has an advert");
      $('#house_mpu3').hide();
      $('#nn_mpu3').show();
    } else {
      console.log("#nn_mpu3 swapped for house promo");
      $('#nn_mpu3').hide();
      $('#house_mpu3').show().show().css('display', 'flex');

      var mpu3_observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          console.log("Mutation on #nn_mpu3!", mutation);
          var attributeValue = $(mutation.target).prop(mutation.attributeName);
          if (attributeValue.includes('adm-ad')) {
            mpu3_observer.disconnect();
            house_promos();
          }
        });
      });
      try {
        mpu3_observer.observe($('#nn_mpu3')[0], {
          attributes: true,
          attributeFilter: ['class']
        });
      } catch(e) {
      }
    }

  } else {
    $('#house_mpu1').hide();
    $('#house_mpu2').hide();
    $('#house_mpu3').hide();
  }

  if (respond_size >= 992) {

    // Desktop leaderboard #1
    if ((
        $('#nn_lb1').height() > 0 &&
        $('#nn_lb1').is(":visible") &&
        $('#nn_lb1').find('div').length > 0 &&
        (
          $('#nn_lb1').hasClass('adm-ad-rendered') ||
          $('#nn_lb1').hasClass('adm-ad-loading') ||
          $('#nn_lb1').hasClass('adm-ad-empty') ||
          $('#nn_lb1').hasClass('adm-ad-skin')
        )
        ) || $('#celtra-skin-container-background').length > 0
    ) {
      console.log("#nn_lb1 has an advert");
      $('#house_lb1').hide();
      $('#nn_lb1').show();
    } else {
      console.log("#nn_lb1 swapped for house promo");
      $('#nn_lb1').hide();
      $('#house_lb1').show();

      var lb1_observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          console.log("Mutation on #nn_lb1!", mutation);
          var attributeValue = $(mutation.target).prop(mutation.attributeName);
          if (attributeValue.includes('adm-ad')) {
            lb1_observer.disconnect();
            house_promos();
          }
        });
      });
      try {
        lb1_observer.observe($('#nn_lb1')[0], {
          attributes: true,
          attributeFilter: ['class']
        });
      } catch(e) {
      }
    }

  } else {
    $('#house_lb1').hide();
  }

  if (respond_size >= 1280) {

    // Desktop leaderboard #3
    if (
      $('#nn_lb3').height() > 0 &&
      $('#nn_lb3').is(":visible") &&
      $('#nn_lb3').find('div').length > 0 &&
      (
        $('#nn_lb3').hasClass('adm-ad-rendered') ||
        $('#nn_lb3').hasClass('adm-ad-loading') ||
        $('#nn_lb3').hasClass('adm-ad-empty') ||
        $('#nn_lb3').hasClass('adm-ad-skin')
      )
    ) {
      console.log("#nn_lb3 has an advert");
      $('#house_lb3').hide();
      $('#nn_lb3').show();
    } else {
      console.log("#nn_lb3 swapped for house promo");
      $('#nn_lb3').hide();
      $('#house_lb3').show();

      var lb3_observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          console.log("Mutation on #nn_lb3!", mutation);
          var attributeValue = $(mutation.target).prop(mutation.attributeName);
          if (attributeValue.includes('adm-ad')) {
            lb3_observer.disconnect();
            house_promos();
          }
        });
      });
      try {
        lb3_observer.observe($('#nn_lb3')[0], {
          attributes: true,
          attributeFilter: ['class']
        });
      } catch(e) {
      }
    }

  } else {
    $('#house_lb3').hide();
  }

}

/**********************************************************************************************/
/** Sage JavaScript Routing *******************************************************************/
/**********************************************************************************************/

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Sage = {
  // All pages
  'common': {
    init: function() {
      // JavaScript to be fired on all pages

      $.ajaxSetup ({ cache: false });

      /* Master resize hook
       */
      $(window).resize(function() {
        var current_size = width_to_respond_size($('#mq').width());
        if (respond_size !== current_size) {
          // If we have reached a breakpoint, call the
          // other function that handles this...
          respond_size = current_size;
          be_responsive(respond_size);
        }

        // Reposition the footer if needed...
        clearTimeout(stick_footer_timeout);    
        stick_footer_timeout = setTimeout(function() { stick_footer(); }, 10);

        // Reset any fixing .fixvh or .fixtp may be doing...
        // NOTE: no debounce on this if we want smooth resizing!
        fixvh_reset();    

        // Fix any odd vertical-centered heights...
        clearTimeout(fixvh_timeout);    
        fixvh_timeout = setTimeout(function() { fixvh(); }, 500);    
      });

      respond_size = width_to_respond_size($('#mq').width());
      be_responsive(respond_size);
      stick_footer();

      /* Search toggle
      */
      $('.nav-search, .vgc-header__search-close').on('click', function(){
        $('.vgc-header__search').toggleClass('search-active');
        if ($('.vgc-header__search').hasClass('search-active')) {
          $('input#search').focus();
        }
      });

      /* Dropdowns not to stay focused
       */
      $('.dropdown-toggle').on('click', function() {
        $(this).blur();
      });  

      /* Featherlight gallery;
      */    
      $('.gallery a').featherlightGallery({
        gallery: {
          fadeIn: 100,
          fadeOut: 100
        },
        openSpeed:    150,
        closeSpeed:   150
      });

      /* ShareThis *
      */
      if ($('.vgc-socialshare').length) {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.defer = true;
        s.src = '//platform-api.sharethis.com/js/sharethis.js#property=5cb896bc9eaba6001253feb3&product=sop';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
        $(".vgc-socialshare").addClass("vgc-socialshare--show");
        console.log("Conditionally loaded: ShareThis");
      } else {
        console.log("No point in loading ShareThis");
      }

      /* OneSignal hook to signoff
      */
      try {
        var scrollobserver = new IntersectionObserver(function(entries, observer) {
          entries.forEach(function(entry) {
            if (entry.isIntersecting) {
              onesignal_trigger();
              observer.unobserve(entry.target);
            }
          });
        }, {rootMargin: "-50px"});
        scrollobserver.observe(document.getElementById("signoff"));
      } catch(e) {
      }

      /* Hide product links from wrong regions
      */
      try {
        switch (window.geo__cc) {
          case 'GB':
            // Show GB links for GB visitors
            $('.product-link[data-region="GB"]').addClass('right-region');
            // Remove US links for GB visitors
            $('.product-link[data-region="US"]').addClass('wrong-region');
          break;
          default:
            // Show US links for everybody else
            $('.product-link[data-region="US"]').addClass('right-region');
            // Remove GB links for everybody else
            $('.product-link[data-region="GB"]').addClass('wrong-region');
          break;
        }
      } catch(e) {
      }

    },
    finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired
    }
  },
  // Home page
  'home': {
    init: function() {
      // JavaScript to be fired on the home page
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    }
  },
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);

/**********************************************************************************************/
/**********************************************************************************************/
/**********************************************************************************************/

})(jQuery); // Fully reference jQuery after this point.